<template>
    <y-modal
        ref="Modal"
        :title="title"
        class="code-selector"
        @close="reset"
    >
        <div slot="body">
            <form
                ref="searchCode"
                class="field"
                @submit.prevent="search"
            >
                <y-form-field
                    v-model="searchTerm"
                    type="text"
                    class="mb0"
                    no-label
                    :placeholder="$t('document.code_selector.placeholder')"
                />
            </form>

            <div
                v-if="results && results.length"
                class="table-wrapper"
            >
                <table class="table bordered striped">
                    <thead>
                        <tr>
                            <th v-if="type === 'group'">
                                {{ $t('document.code_selector.code') }}
                            </th>
                            <th>{{ $t('document.code_selector.desc') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="item in results"
                            :key="item.id"
                            class="code-selector__row"
                            @click="selectCode(item)"
                        >
                            <td v-if="type === 'group'">
                                {{ item.value | digits }}
                            </td>
                            <td>{{ item.label | digits }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <y-empty-state
                v-if="searched && results && !results.length"
                class="mb0"
                :message="$t('document.code_selector.empty')"
            />

            <y-empty-state
                v-if="!searched"
                class="mb0"
                :message="$t('document.code_selector.search')"
            />
        </div>

        <template
            v-if="!$wait.is('loading-post')"
            slot="footer"
        >
            <div class="ta-l w-100">
                <y-button
                    class="ml5"
                    @click.prevent.native="close"
                >
                    {{ $t('button.cancel') }}
                </y-button>
            </div>
        </template>
    </y-modal>
</template>

<script>

    import { YModal, YFormField } from '@deps';
    import YEmptyState from '@deps/EmptyState';

    export default {
        name: 'CodeSelectorModal',

        components: {
            YModal,
            YFormField,
            YEmptyState,
        },

        props: {
            groupComboList: [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                type      : '',
                index     : null,
                group     : null,
                searchTerm: '',
                searched  : false,
                results   : [],
            };
        },

        computed: {
            /**
             * Title of the Modal
             */
            title() {
                return this.type === 'group' ? this.$t('document.code_selector.modal_title.group') : this.$t('document.code_selector.modal_title.segregate');
            },
        },

        methods: {

            /**
             * openModal
             *
             * @param index
             * @param type
             * @param group
             */
            open(index, type, group) {
                this.$set(this, 'type', type);
                this.$set(this, 'index', index);
                this.$set(this, 'group', group);
                if (this.type !== 'group') {
                    this.search();
                }

                if (this.type === 'group' && this.groupComboList) {
                    this.$set(this, 'results', this.groupComboList);
                    if (this.results && this.results.length) {
                        this.searched = true;
                    }
                } else {
                    this.search();
                }

                this.$refs.Modal.open();
            },

            /**
             * Close modal
             */
            close() {
                this.reset();
                this.$refs.Modal.close();
            },

            /**
             * Emit Selected Code
             *
             * @param item
             */
            selectCode(item) {
                this.$emit('select', this.index, this.type, item);
                this.reset();
                this.close();
            },

            /**
             * Reset the form
             */
            reset() {
                this.searchTerm = '';
                this.results = [];
                this.searched = false;
            },

            /**
             * Search the code
             */
            search() {
                this.results = [];
                const params = {
                    search: this.searchTerm,
                };
                if (this.group) {
                    params.group = this.group;
                }
                return this.$services.Accountant.groupSegregatesCombo(params).then((response) => {
                    this.results = response.data.results;
                    if (this.searchTerm || (this.results && this.results.length > 0)) {
                        this.searched = true;
                    }
                }).catch((error) => {
                    this.handleError(error);
                });
            },
        },
    };

</script>
