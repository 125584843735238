/*-----------------------------------------------------------------
- Hotkey mixin
-----------------------------------------------------------------*/

export default {
    name: 'HotkeyMixin',
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            selectedRowCount: 0,
            selectedRow     : [
                {},
            ],
        };
    },
    
    computed: {
        /**
         * Define keymap for methods
         */
        keymap() {
            return {
                'ctrl+shift+a' : this.addRow,
                'shift+del'    : this.removePosting,
                'shift+ctrl+f3': this.makeAmountSwitch,
                'shift+up'     : this.up,
            };
        },
    },
    
    methods: {
        /**
         * Count the selected row
         */
        countSelected() {
            this.selectedRowCount = 0;
            this.selectedRow.forEach((item) => {
                if (item.active) {
                    this.selectedRowCount += 1;
                }
            });
        },
        
        /**
         * Make Amount Switch in all selectedRow
         */
        makeAmountSwitch() {
            this.selectedRow.forEach((item) => {
                if (item.active) {
                    this.amountSwitch(item.index);
                }
            });
        },
        
        /**
         * Switch Debtor and Creditor
         *
         * @param index
         */
        amountSwitch(index) {
            const origDebtor = this.model[index].debtor;
            const origCreditor = this.model[index].creditor;
            this.$set(this.model[index], 'debtor', origCreditor);
            this.$set(this.model[index], 'creditor', origDebtor);
        },
        
        /**
         * Copy field data from previous model in all selectedRow
         */
        makeCopyField() {
            this.selectedRow.forEach((item) => {
                if (item.active) {
                    this.copyField(item.index);
                }
            });
        },
        
        /**
         * Copy field data from previous model
         *
         * @param index
         */
        copyField(index) {
            const desModel = this.model[index];
            const clone = { ...this.model[index - 1] };
            if (index > 0) {
                this.$set(desModel, '', clone);
                this.model[index] = clone;
            }
        },
        
        /**
         * Select last posting
         */
        selectLastPosting() {
            const { length } = this.model;
            this.selectPosting(length - 1);
        },
        
        /**
         * Move selected Item
         */
        movePosting() {
            const { length } = this.model;
            this.selectedRow.forEach((item) => {
                if (item.index !== length - 1) {
                    this.$set(item, 'active', false);
                }
            });
            this.selectLastPosting();
        },
        
        /**
         * Get last active row
         */
        getLastActive() {
            let active;
            this.selectedRow.forEach((item) => {
                if (item.active) {
                    active = item.index;
                }
            });
            return active;
        },
        
        /**
         * Move up in the posting list
         */
        up() {
            let active;
            if (this.selectedRowCount > 1) {
                active = this.getLastActive();
                this.selectedRowCount = 1;
            } else {
                this.selectedRow.forEach((item) => {
                    if (item.active) {
                        active = item.index;
                    }
                });
            }
            
            // Move selected row
            this.selectedRow.forEach((item) => {
                if (item.index !== active - 1) {
                    this.$set(item, 'active', false);
                }
                if (item.index === active - 1) {
                    this.$set(item, 'active', true);
                }
            });
        },
    },
};
