<template>
    <div>
        <div class="row">
            <!-- Document Base Info -->
            <div class="col-sm-8 document-head-col">
                <y-panel :title="$t('document.details')">
                    <form
                        id="document_form"
                        @submit.prevent="submit(save)"
                    >
                        <y-form-field
                            v-model="model.description"
                            type="text"
                            rows="2"
                            name="document_desc"
                            :disabled="!editable"
                            validation="required"
                        />

                        <div class="row">
                            <div class="col">
                                <y-form-date-picker
                                    v-model="model.effected_at"
                                    type="datetime"
                                    name="date"
                                    :disabled="!editable"
                                    validation="required"
                                />
                            </div>
                            <div class="col">
                                <y-form-field
                                    v-if="document"
                                    v-model="model.currency_id"
                                    type="select"
                                    name="currency"
                                    :options="currencies"
                                    label-field="full_title"
                                    value-field="id"
                                    :disabled="!editable"
                                />
                                <y-form-field
                                    v-else
                                    type="text"
                                    readonly
                                    name="currency"
                                    :value="document.currency.full_title"
                                />
                            </div>
                            <div class="col">
                                <y-form-field
                                    v-model="model.status"
                                    type="select"
                                    name="status"
                                    class="document-status"
                                    :class="[status]"
                                    :options="statusOptions"
                                    label-field="label"
                                    value-field="value"
                                    validation="required"
                                    :disabled="!editable"
                                />
                            </div>
                        </div>
                    </form>
                </y-panel>
            </div>

            <!-- Document Info -->
            <div class="col-sm-4 document-head-col">
                <y-panel class="document-sidebar p15">
                    <div :class="{ disabled: !document }">
                        <div class="document-number">
                            <span>{{ $t('document.idx') }}</span>
                            <span>{{ idx | digits }}</span>
                        </div>
                        <div class="document-number">
                            <span>{{ $t('document.turning_number') }}</span>
                            <span>{{ turningNumber | digits }}</span>
                        </div>
                        <div class="document-number">
                            <span>{{ $t('document.create_system') }}</span>
                            <span>{{ metadata.client_title ? metadata.client_title : `-` }}</span>
                        </div>
                        <div class="document-number">
                            <span>{{ $t('document.create_user') }}</span>
                            <span>{{ metadata.user_name ? metadata.user_name : `-` }}</span>
                        </div>
                    </div>
                </y-panel>
            </div>
        </div>

        <!-- Posting Table -->
        <y-posting-editor
            v-model="model.postings"
            :editable="editable"
            :posting="document.postings"
            :group="group"
            @balance="docsBalance"
        />
    </div>
</template>

<script>

    import FormMixin from '@/mixins/Form';
    import DocumentMixin from '@/modules/accountant/mixins/Document';
    import { YFormField } from '@deps';
    import YFormDatePicker from '@deps/form/elements/DatePicker';
    import YPostingEditor from '@/modules/accountant/components/documents/PostingEditor';

    export default {
        name: 'DocumentEditor',

        components: {
            YFormField,
            YFormDatePicker,
            YPostingEditor,
        },

        mixins: [
            FormMixin,
            DocumentMixin,
        ],

        props: {
            currencies: Array,
            document  : Object,
            metadata  : Object,
            group     : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model: {
                    id         : this.$route.params.id ? this.$route.params.id : null,
                    effected_at: new Date().getTime() / 1000,
                },

                balance: null,
                timeout: null,
            };
        },

        computed: {
            /**
             * Return idx
             */
            idx() {
                return this.get(this.document, 'idx', '-');
            },

            /**
             * Return turning number
             */
            turningNumber() {
                return this.get(this.document, 'turning_number') || '-';
            },

            /**
             * Status of options
             */
            statusOptions() {
                return this.get(this.metadata, 'statuses_combo') || [];
            },

            /**
             * Status of document
             */
            status() {
                if (!this.document) {
                    return 'new';
                }
                if (this.document.finalized) {
                    return 'finalized';
                }
                if (this.document.balanced) {
                    return 'balanced';
                }
                return 'unbalanced';
            },

            /**
             * Status Label
             */
            statusLabel() {
                return this.$t(`document.status.${this.status}`);
            },

            /**
             * Check if the form is editable
             */
            editable() {
                return this.$route.params.id ? (this.get(this.metadata, 'can_edit') && this.get(this.metadata, 'editable')) : true;
            },
        },

        watch: {
            document: {
                /**
                 * Handle watch document
                 *
                 * @param newVal
                 * @param oldVal
                 */
                handler(newVal, oldVal) {
                    if (!this.isEqual(newVal, oldVal)) {
                        this.modelData();
                    }
                },
                deep: true,
            },

            currencies: {
                /**
                 * Watch change currencies
                 *
                 * @param val
                 */
                handler(val) {
                    if (val) {
                        this.model.currency_id = val.find((el) => el.is_default === 1).id;
                    }
                },
                deep     : true,
                immediate: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            this.modelData();
        },

        methods: {
            /**
             * Fetch Data from model in edit mode
             */
            modelData() {
                if (this.isEdit) {
                    this.$set(this.model, 'description', this.document.description);
                    this.$set(this.model, 'effected_at', this.document.effected_at);
                    this.$set(this.model, 'id', this.document.id);
                    this.$set(this.model, 'postings', this.document.postings);
                    this.$set(this.model, 'status', this.document.status);
                }
            },

            /**
             * Save document
             */
            save() {
                this.$wait.start('saving-document');
                let postingCheck = false;
                let service;
                if (this.model.postings) {
                    const count = this.model.postings.length;
                    if (count > 0 && !this.model.postings[count - 1].group) {
                        this.model.postings.splice((count - 1), 1);
                    }
                    postingCheck = true;
                }
                if (postingCheck || this.model.postings.length === 0) {
                    service = this.$services.Accountant.documentSave(this.model).then((response) => {
                        this.$toast.success(this.$t('messages.save.success', { type: this.$t('document.item') }));
                        if (!this.isEdit) {
                            this.$router.push({
                                name  : 'accountant-documents-edit',
                                params: {
                                    id: response.data.metadata.hashid,
                                },
                            });
                        }
                        this.$emit('refresh');
                    }).catch((error) => {
                        this.handleError(error);
                    }).finally(() => {
                        this.$wait.end('saving-document');
                    });
                }
                return service;
            },

            /**
             * Document Balance
             *
             * @param diff
             */
            docsBalance(diff) {
                this.$set(this, 'balance', diff);
            },

            /**
             * Check balance and status
             */
            checkBalance() {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    if (this.model.status) {
                        if (this.balance !== 0 && this.model.status !== 0) {
                            this.$set(this.model, 'status', 0);
                            this.$toast.error(this.$t('document.balance.change_status'));
                        }
                    }
                }, 500);
            },
        },
    };

</script>
