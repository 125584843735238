/*-----------------------------------------------------------------
- Document mixin
-----------------------------------------------------------------*/
export default {
    
    name: 'DocumentMixin',
    
    computed: {
        /**
         * Return type of page
         *
         * @returns {string}
         */
        type() {
            return this.$route.name === 'accountant-documents-edit' ? 'edit' : 'create';
        },
        
        /**
         * Check if route is edit
         *
         * @returns {boolean}
         */
        isCreate() {
            return this.type === 'create';
        },
        
        /**
         * Check if route is edit
         *
         * @returns {boolean}
         */
        isEdit() {
            return this.type === 'edit';
        },
        
        /**
         * Check permission to edit
         *
         * @returns {*}
         */
        canEdit() {
            return this.isEdit ? this.get(this.document, 'canEdit') : true;
        },
        
    },
    
};
