<template>
    <div>
        <y-page-head
            :title="$t(`document.${type}`)"
            icon="md-file-table-outline"
        >
            <template slot="actions">
                <y-button
                    id="refresh-button"
                    type="button"
                    icon="md-refresh"
                    aria-label="Refresh"
                    @click.prevent="reFetch"
                />

                <y-button
                    type="button"
                    class="color-red"
                    @click.prevent="backToList"
                >
                    {{ $t('button.back') }}
                </y-button>

                <y-button
                    v-if="canEdit && canPrint"
                    tag="router-link"
                    :to="{ name: 'accountant-documents-print' }"
                    target="_blank"
                >
                    {{ $t('button.print') }}
                </y-button>

                <y-button v-if="canView">
                    {{ $t('button.excel') }}
                </y-button>

                <y-button
                    v-if="canEdit"
                    :key="`document.${type}_button`"
                    color="blue"
                    form="document_form"
                    loading-on="saving-document"
                >
                    {{ $t(`document.${type}_button`) }}
                </y-button>
            </template>
        </y-page-head>

        <y-loading
            :active="$wait.is('loading-page')"
            height="calc( 100vh - 239px )"
        >
            <main>
                <y-document-editor
                    :currencies="currencies"
                    :document="document"
                    :metadata="metadata"
                    :group="groupsCombo"
                    @refresh="reFetch"
                />
            </main>
        </y-loading>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import YDocumentEditor from '@/modules/accountant/components/documents/DocumentEditor';

    export default {

        name: 'DocumentEdit',

        components: {
            YDocumentEditor,
        },

        mixins: [PageMixin],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
                this.$bc(this.$t('breadcrumbs.commenting')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t(`document.${this.type}`),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id              : this.$route.params.id,
                document        : null,
                postings        : null,
                groups          : null,
                isAfterQuickMode: false,
                currencies      : null,
                metadata        : null,
                groupsCombo     : null,
            };
        },

        computed: {
            /**
             * Return type of page
             *
             * @returns {string}
             */
            type() {
                return this.$route.name === 'accountant-documents-edit' ? 'edit' : 'create';
            },

            /**
             * Check if route is edit
             *
             * @returns {boolean}
             */
            isCreate() {
                return this.type === 'create';
            },

            /**
             * Check if route is edit
             *
             * @returns {boolean}
             */
            isEdit() {
                return this.type === 'edit';
            },

            /**
             * Check permission to edit
             *
             * @returns {*}
             */
            canEdit() {
                return this.isEdit ? this.get(this.metadata, 'can_edit') : true;
            },

            /**
             * Check permission to print
             *
             * @returns {*}
             */
            canPrint() {
                return this.isEdit ? this.get(this.metadata, 'can_print') : true;
            },

            /**
             * Check permission to view
             *
             * @returns {*}
             */
            canView() {
                return this.isEdit ? this.get(this.metadata, 'can_view') : true;
            },

        },

        methods: {

            /**
             * Fetch currencies
             *
             * @returns {*}
             */
            fetch() {
                const currency = this.$services.list('exchange-currency', {
                    including: ['full_title', 'is_default'],
                });

                const group = this.$services.Accountant.groupSegregatesCombo();

                const params = {};
                if (this.$route.params.id) {
                    params.id = this.$route.params.id;
                }
                const document = this.$services.Accountant.documentFetch(params);

                return Promise.all([currency, group, document]).then((responses) => {
                    this.currencies = responses[0].data.results;
                    this.groupsCombo = responses[1].data.results;
                    this.document = responses[2].data.results;
                    this.metadata = responses[2].data.metadata;
                });
            },

            /**
             * Back to documents list
             */
            backToList() {
                this.$router.push({
                    name: 'accountant-documents-list',
                });
            },

        },

    };
</script>
