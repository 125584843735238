<template>
    <div>
        <y-panel
            v-hotkey="keymap"
            :title="$t('document.posting.title')"
            class="posting-editor"
        >
            <template slot="functions">
                <y-button
                    v-if="editable"
                    key="add-posting"
                    class="color-blue"
                    :disabled="!model[model.length - 1].group"
                    @click="addRow()"
                >
                    {{ $t('document.posting.add') }}
                </y-button>
                <y-button
                    v-if="editable"
                    key="remove-posting"
                    class="color-red"
                    :disabled="!selectedRowCount"
                    @click="removePosting"
                >
                    {{ $t('document.posting.remove') }}
                </y-button>
            </template>

            <y-loading
                :active="$wait.is('posting-loading')"
                height="250px"
            >
                <form id="add-posting-form">
                    <div class="table-wrapper posting-editor__table">
                        <table class="table bordered mb0">
                            <thead>
                                <tr>
                                    <th class="posting-editor__table__row">
                                        {{ $t('document.posting.columns.row') }}
                                    </th>
                                    <th class="posting-editor__table__group">
                                        {{ $t('document.posting.columns.group') }}
                                    </th>
                                    <th class="posting-editor__table__segregate">
                                        {{ $t('document.posting.columns.segregate') }}
                                    </th>
                                    <th class="posting-editor__table__desc">
                                        {{ $t('document.posting.columns.description') }}
                                    </th>
                                    <th class="posting-editor__table__debtor">
                                        {{ $t('document.posting.columns.debtor') }}
                                    </th>
                                    <th class="posting-editor__table__creditor">
                                        {{ $t('document.posting.columns.creditor') }}
                                    </th>
                                    <th class="posting-editor__table__note">
                                        {{ $t('document.posting.columns.manual_code') }}
                                    </th>
                                    <th class="posting-editor__table__date">
                                        {{ $t('document.posting.columns.manual_date') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(row, index) in model"
                                    :key="`posting-row-${index}`"
                                    class="posting-editor__row"
                                    :class="{'selected': selectedRow[index] && selectedRow[index].active}"
                                >
                                    <td
                                        class="posting-row"
                                        @click="selectPosting(index)"
                                    >
                                        <span class="row-number">{{ (index + 1) | digits }}</span>
                                        <y-button
                                            type="button"
                                            class="row-delete"
                                            icon="md-delete"
                                            size="xs"
                                            :disabled="!editable"
                                            @click.prevent="removePosting(index)"
                                        />
                                    </td>
                                    <td>
                                        <y-form-field
                                            v-model="model[index].group"
                                            type="number"
                                            class="mb0 posting-editor__table__group posting_editor__input"
                                            :class="{'has-error': model[index].group && !model[index]._valid_group}"
                                            no-label
                                            :readonly="!editable"
                                            :data-tooltip="model[index].group && !model[index]._valid_group ? groupTooltip() : false"
                                            @blur="addRow(index)"
                                            @input="validateCode(index, model[index].group)"
                                        />
                                        <y-button
                                            class="f-l"
                                            size="xs"
                                            :disabled="!editable"
                                            icon="md-cogs"
                                            @click.prevent.native="$refs.CodeSelectorModal.open(index, 'group')"
                                        />
                                    </td>
                                    <td>
                                        <y-form-field
                                            v-model="model[index].segregate"
                                            type="number"
                                            class="mb0 posting-editor__table__segregate posting_editor__input"
                                            :class="{'has-error': model[index].segregate && !model[index]._valid_segregate}"
                                            no-label
                                            :readonly="!editable"
                                            :disabled="!model[index]._has_segregate"
                                            :data-tooltip="segregateTooltip(index)"
                                            @input="validateCode(index, model[index].group, model[index].segregate)"
                                        />
                                        <y-button
                                            v-if="model[index]._has_segregate"
                                            class="f-l"
                                            size="xs"
                                            :disabled="!editable"
                                            icon="md-cogs"
                                            @click.prevent.native="$refs.CodeSelectorModal.open(index, 'segregate', model[index].group)"
                                        />
                                    </td>
                                    <td>
                                        <y-form-field
                                            v-model="model[index].description"
                                            type="text"
                                            class="mb0 posting-editor__table__desc posting_editor__input"
                                            no-label
                                            :readonly="!editable"
                                        />
                                    </td>
                                    <td>
                                        <y-form-field
                                            v-model="model[index].debtor"
                                            type="number"
                                            name="debtor"
                                            format="separated"
                                            class="mb0 posting-editor__table__debtor posting_editor__input"
                                            no-label
                                            :readonly="!editable"
                                            @input="amountBlur(index, 'debtor')"
                                        />
                                    </td>
                                    <td>
                                        <y-form-field
                                            v-model="model[index].creditor"
                                            type="number"
                                            name="debtor"
                                            format="separated"
                                            class="mb0 posting-editor__table__creditor posting_editor__input"
                                            no-label
                                            :readonly="!editable"
                                            @input="amountBlur(index, 'creditor')"
                                        />
                                    </td>
                                    <td>
                                        <y-form-field
                                            v-model="model[index].custom_tracking_number"
                                            type="text"
                                            class="mb0 posting-editor__table__note posting_editor__input"
                                            no-label
                                            :readonly="!editable"
                                        />
                                    </td>
                                    <td>
                                        <y-form-date-picker
                                            v-model="model[index].custom_tracking_at"
                                            type="datetime"
                                            class="mb0 posting-editor__table__date posting_editor__input"
                                            no-label
                                            :readonly="!editable"
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </form>
            </y-loading>
        </y-panel>

        <div class="table-wrapper">
            <table class="table">
                <tbody>
                    <tr>
                        <td>{{ $t('document.posting.columns.row_count', [rowCount]) }}</td>
                        <td>
                            {{ $t('document.posting.columns.debtor_sum') }}
                            <y-form-amount
                                class="mb0 d-ib"
                                :element="{ value: debtorSum }"
                            />
                        </td>
                        <td>
                            {{ $t('document.posting.columns.creditor_sum') }}
                            <y-form-amount
                                class="mb0 d-ib"
                                :element="{ value: creditorSum }"
                            />
                        </td>
                        <td>
                            {{ $t('document.posting.columns.amount_difference') }}
                            <y-form-amount
                                class="mb0 d-ib"
                                :element="{ value: amountDiff }"
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <y-code-selector-modal
            ref="CodeSelectorModal"
            :group-combo-list="group"
            @select="selectCode"
        />
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import FormMixin from '@/mixins/Form';
    import { digits } from '@nodes/helpers/number';
    import { YFormField, YFormDatePicker } from '@deps';
    import YFormAmount from '@deps/form/elements/Amount';
    import HotkeyMixin from '@/modules/accountant/mixins/Hotkey.js';
    import YCodeSelectorModal from '@/modules/accountant/components/documents/CodeSelectorModal';

    export default {
        name: 'PostingEditor',

        components: {
            YFormField,
            YFormDatePicker,
            YCodeSelectorModal,
            YFormAmount,
        },

        mixins: [
            FormMixin,
            HotkeyMixin,
        ],

        props: {
            editable: {
                type   : Boolean,
                default: false,
            },
            posting: [Object, Array],
            group  : [Object, Array],
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                timeout         : null,
                fetchPosting    : false,
                keyHelper       : generateId(),
                model           : [],
                errorContainer  : [],
                selectedRowCount: 0,
                selectedRow     : [
                    {},
                ],
            };
        },

        computed: {
            /**
             * Count of the rows
             */
            rowCount() {
                return digits(this.model.length, this.$i18n.locale);
            },

            /**
             * Sum of the Debtor
             */
            debtorSum() {
                let sum = 0;
                this.model.forEach((i) => {
                    if (i.debtor) {
                        sum += Number(i.debtor);
                    }
                });
                return sum;
            },

            /**
             * Sum of the Creditor
             */
            creditorSum() {
                let sum = 0;
                this.model.forEach((i) => {
                    if (i.creditor) {
                        sum += Number(i.creditor);
                    }
                });
                return sum;
            },

            /**
             * Difference between Debtor and creditor
             */
            amountDiff() {
                const debtor = digits(this.debtorSum, 'en');
                const creditor = digits(this.creditorSum, 'en');
                let diff = debtor - creditor;
                if ((diff) < 0) {
                    diff *= -1;
                }
                this.$emit('balance', diff);
                return diff;
            },
        },

        watch: {
            model: {
                /**
                 * Emit input when model changes
                 */
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },

            posting: {
                /**
                 * Check if the posting changed
                 */
                handler() {
                    if (this.$route.params.id) {
                        this.modelData();
                    }
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        mounted() {
            this.keyHelper = generateId();
        },

        /**
         * @inheritDoc
         */
        created() {
            if (!this.posting.length) {
                this.newRow();
            }
            this.modelData();
        },

        methods: {
            /**
             * Set model data from posting in edit mode
             */
            modelData() {
                this.$wait.start('posting-loading');
                if (this.posting.length > 0) {
                    this.model = [];
                    this.selectedRow = [];
                    this.posting.forEach((post, index) => {
                        this.model.push(post);
                        this.$set(this.model[index], '_valid_group', true);
                        this.$set(this.model[index], '_valid_segregate', true);
                    });
                    Object.keys(this.model).forEach(() => {
                        this.selectedRow.push({
                            active: false,
                        });
                    });
                }
                this.$wait.end('posting-loading');
            },

            /**
             * New Row structure
             */
            newRow() {
                this.selectedRow.push({
                    active: false,
                });
                this.model.push({
                    id                    : null,
                    group                 : null,
                    segregate             : null,
                    _has_segregate        : false,
                    _valid_group          : true,
                    _valid_segregate      : true,
                    description           : null,
                    debtor                : 0,
                    creditor              : 0,
                    custom_tracking_number: '',
                    custom_tracking_at    : '',
                });
            },

            /**
             * Add row in posting table
             *
             * @param index
             */
            addRow(index) {
                this.keyHelper = generateId();
                const count = this.model.length;
                if (!index && (count > 0 && this.model[count - 1].group)) {
                    this.newRow();
                }
                if (index && count > 0 && this.model[count - 1].group) {
                    this.newRow();
                }
                if (!count) {
                    this.newRow();
                }
            },

            /**
             * Select Posting
             *
             * @param index
             */
            selectPosting(index) {
                this.$set(this.selectedRow[index], 'index', index);
                this.$set(this.selectedRow[index], 'active', !this.selectedRow[index].active);
                this.countSelected();
            },

            /**
             * Remove Posting
             *
             * @param index
             */
            removePosting(index) {
                if (index || index === 0) {
                    this.model.splice(index, 1);
                } else {
                    this.selectedRow.forEach((item) => {
                        if (item.active) {
                            this.model.splice(item.index, 1);
                            this.$set(item, 'active', false);
                        }
                    });
                }
            },

            /**
             * Make Amount Zero if another entered
             *
             * @param index
             * @param value
             */
            amountBlur(index, value) {
                if (this.model[index][value] !== 0) {
                    if (value === 'debtor') {
                        this.$set(this.model[index], 'creditor', 0);
                    } else if (value === 'creditor') {
                        this.$set(this.model[index], 'debtor', 0);
                    }
                }
            },

            /**
             * Code Validate
             *
             * @param index
             * @param group
             * @param segregate
             */
            codeCheck(index, group, segregate) {
                this.$set(this.model[index], '_valid_group', true);
                this.$set(this.model[index], '_valid_segregate', true);
                if (group) {
                    this.$set(this.model[index], 'group', group);
                }
                const params = {
                    group,
                    segregate,
                };
                return this.$services.Accountant.groupsSegregateCheck(params).then((response) => {
                    if (group || segregate) {
                        if (group) {
                            this.$set(this.model[index], '_valid_group', response.data.results.group);
                            this.$set(this.model[index], '_has_segregate', response.data.results.has_segregate);
                        }
                        if (segregate) {
                            this.$set(this.model[index], '_valid_segregate', response.data.results.segregate);
                        }

                        if (response.data.results.segregate_code) {
                            this.$set(this.model[index], 'segregate', response.data.results.segregate_code);
                        }
                    }
                }).catch((error) => {
                    this.handleError((error));
                });
            },

            /**
             * Validate group and segregate ids with timeout
             *
             * @param index
             * @param group
             * @param segregate
             */
            validateCode(index, group, segregate) {
                clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                    this.codeCheck(index, group, segregate);
                }, 250);
            },

            /**
             * Code Select Value
             *
             * @param index
             * @param key
             * @param value
             */
            selectCode(index, key, value) {
                const count = this.model.length;
                if (key === 'group') {
                    this.$set(this.model[index], `${key}`, value.value);
                    this.$set(this.model[index], '_has_segregate', value.has_segregate);
                    if (this.model[count - 1].group) {
                        this.addRow();
                    }
                }
                if (key === 'segregate') {
                    this.validateCode(index, this.model[index].group, value.value);
                }
            },

            /**
             * Segregate Tooltip
             */
            groupTooltip() {
                return this.$t('document.posting.tooltip.not_valid_group');
            },

            /**
             * Segregate Tooltip
             *
             * @param index
             */
            segregateTooltip(index) {
                if (this.model[index].group && !this.model[index]._has_segregate) {
                    return this.$t('document.posting.tooltip.has_no_segregate');
                }
                if (this.model[index].group && this.model[index]._has_segregate) {
                    return false;
                }
                if (!this.model[index]._valid_segregate) {
                    return this.$t('document.posting.tooltip.not_valid_segregate');
                }
                return this.$t('document.posting.tooltip.select_group_first');
            },

        },
    };

</script>
