/*-----------------------------------------------------------------
- Theme Grid mixin
-----------------------------------------------------------------*/
import PageMixin from '@/mixins/Page';
import { YPanelGrid } from '@deps';

export default {
    name: 'GridPageMixin',

    mixins: [
        PageMixin,
    ],

    components: {
        YPanelGrid,
    },

    /**
     * @inheritDoc
     */
    created() {
        this.watchQuery = false;
    },
};
