/*-----------------------------------------------------------------
- MIXINS: Page
-----------------------------------------------------------------*/

export default {
    name: 'PageMixin',

    /**
     * Start `loading-page` before create
     */
    beforeCreate() {
        this.$wait.start('loading-page');
    },
    
    /**
     * Start `loading-page` before leaving current route
     *
     * @param to
     * @param from
     * @param next
     */
    beforeRouteLeave(to, from, next) {
        this.$wait.start('loading-page');
        
        setTimeout(() => next(), 400);
    },
    
    /**
     * Start `loading-page` before updating route
     *
     * @param to
     * @param from
     * @param next
     */
    beforeRouteUpdate(to, from, next) {
        this.$wait.start('loading-page');
        
        setTimeout(() => next(), 400);
    },
    
    /**
     * @inheritDoc
     */
    breadcrumbs() {
        return [
            this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
        ];
    },
    
    /**
     * @inheritDoc
     */
    data() {
        return {
            pageTitle : null,
            watchQuery: false,
        };
    },
    
    /**
     * @inheritDoc
     */
    metaInfo() {
        return {
            title: this.pageTitle,
        };
    },
    
    /**
     * @inheritDoc
     */
    created() {
        this.watchQuery = true;
        this.fetchWrapper();
    },
    
    watch: {
        '$route': function () { // eslint-disable-line
            if (this.watchQuery) {
                this.fetchWrapper();
            }
        },
    },
    
    methods: {
        
        /**
         * Fetching data wrapper
         */
        async fetchWrapper() {
            await this.fetch().then(() => this.$wait.end('loading-page'));
        },
        
        /**
         * Fetching data
         */
        fetch() {
            return new Promise((resolve) => resolve());
        },
        
        /**
         * Fetching data
         */
        reFetch() {
            this.$wait.start('loading-page');
            this.fetchWrapper();
        },
        
    },
    
};
