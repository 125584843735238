<template>
    <div class="document-print">
        <y-page-head
            :title="$t('document.print')"
            icon="md-file-table-outline"
        >
            <template slot="actions">
                <y-button
                    id="print-button"
                    type="button"
                    @click.prevent="printSection"
                >
                    {{ $t('document.print_button') }}
                </y-button>
            </template>
        </y-page-head>

        <div class="invoice-wrapper">
            <y-loading
                :active="$wait.is('loading-page')"
                height="calc( 100vh - 239px )"
            >
                <div
                    id="invoice"
                    ref="invoice"
                    class="invoice"
                >
                    <!-- Document Title -->
                    <div class="row align-items-center invoice-row invoice-header">
                        <div class="col-print-4 col-12">
                            <h3 class="invoice-title">
                                {{ $t('document.print') }}
                            </h3>
                        </div>
                    </div>

                    <!-- Document Info -->
                    <div class="row invoice-row">
                        <div
                            v-if="document"
                            class="col-12"
                        >
                            <div class="row invoice-block-row">
                                <div class="col-print-3 col-12 col-md-9">
                                    <p class="invoice-block">
                                        <span class="invoice-block-key">{{ $t('document.info.description') }}</span>
                                        <span class="invoice-block-value">{{ document.description }}</span>
                                    </p>
                                </div>
                                <div class="col-print-1 col-12 col-md-3">
                                    <p class="invoice-block">
                                        <span class="invoice-block-key">{{ $t('document.info.status') }}</span>
                                        <span class="invoice-block-value">{{ $t(`document.status.${status}`) }}</span>
                                    </p>
                                </div>
                                <div class="col-print-1 col-12 col-md-3">
                                    <p class="invoice-block">
                                        <span class="invoice-block-key">{{ $t('document.info.currency') }}</span>
                                        <span class="invoice-block-value">{{ document.currency.title }}</span>
                                    </p>
                                </div>
                                <div class="col-print-1 col-12 col-md-3">
                                    <p class="invoice-block">
                                        <span class="invoice-block-key">{{ $t('document.info.number') }}</span>
                                        <span
                                            v-if="document.idx && document.idx !==0"
                                            class="invoice-block-value"
                                        >
                                            {{ document.idx | digits }}
                                        </span>
                                        <span
                                            v-else
                                            class="invoice-block-value"
                                        >
                                            {{ `-` }}
                                        </span>
                                    </p>
                                </div>
                                <div class="col-print-1 col-12 col-md-3">
                                    <p class="invoice-block">
                                        <span class="invoice-block-key">{{ $t('document.info.turningNumber') }}</span>
                                        <span class="invoice-block-value">{{ document.turning_number | digits }}</span>
                                    </p>
                                </div>
                                <div class="col-print-1 col-12 col-md-3">
                                    <p class="invoice-block">
                                        <span class="invoice-block-key">{{ $t('document.info.date') }}</span>
                                        <span class="invoice-block-value">{{ document.effected_at | date('YYYY/MM/DD HH:mm', $i18n.locale) | digits }}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Posting Grid -->
                    <div
                        v-if="postings"
                        class="row invoice-row"
                    >
                        <div class="col-12 invoice-subtitle-bg">
                            <h3 class="invoice-subtitle">
                                {{ $t('icib.invoice.product_title') }}
                            </h3>
                        </div>
                        <div class="col-12 invoice-table">
                            <div class="invoice-table-container">
                                <div class="table-wrapper">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th
                                                    v-for="column in postings_table_title"
                                                    :key="column.id"
                                                    class="invoice-block-key no-dots"
                                                >
                                                    {{ column.title }}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(row, index) in postings"
                                                :key="index"
                                            >
                                                <td class="invoice-block-value">
                                                    {{ (index + 1) | digits }}
                                                </td>
                                                <td class="invoice-block-value">
                                                    {{ row.group.title | digits }}
                                                </td>
                                                <td class="invoice-block-value">
                                                    {{ row.description | digits }}
                                                </td>
                                                <td class="invoice-block-value">
                                                    <y-form-amount
                                                        class="mb0"
                                                        :element="{ value: row.debtor }"
                                                    />
                                                </td>
                                                <td class="invoice-block-value">
                                                    <y-form-amount
                                                        class="mb0"
                                                        :element="{ value: row.creditor }"
                                                    />
                                                </td>
                                            </tr>
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <td colspan="3">
                                                    {{ $t('document.sum_all') }}
                                                </td>
                                                <td>
                                                    <y-form-amount
                                                        class="mb0"
                                                        :element="{ value: allDebtor }"
                                                    />
                                                </td>
                                                <td>
                                                    <y-form-amount
                                                        class="mb0"
                                                        :element="{ value: allCreditor }"
                                                    />
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </y-loading>
        </div>
    </div>
</template>

<script>

    import PageMixin from '@/mixins/Page';
    import YFormAmount from '@deps/form/elements/Amount';
    import DocumentMixin from '@/modules/accountant/mixins/Document';

    export default {
        name: 'DocumentPrint',

        components: {
            YFormAmount,
        },

        mixins: [
            PageMixin,
            DocumentMixin,
        ],

        /**
         * @inheritDoc
         */
        breadcrumbs() {
            return [
                this.$bc('dashboard', this.$t('breadcrumbs.dashboard')),
            ];
        },

        /**
         * @inheritDoc
         */
        metaInfo() {
            return {
                title: this.$t('breadcrumbs.persons.users.print'),
            };
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                id         : this.$route.params.id,
                document   : null,
                postings   : null,
                allDebtor  : null,
                allCreditor: null,

                postings_table_title: [
                    {
                        title: this.$t('document.posting.table.title.row'),
                        id   : 'row',
                    },
                    {
                        title: this.$t('document.posting.table.title.group_label'),
                        id   : 'group_code',
                    },
                    {
                        title: this.$t('document.posting.table.title.description'),
                        id   : 'description',
                    },
                    {
                        title: this.$t('document.posting.table.title.debtor'),
                        id   : 'debtor',
                    },
                    {
                        title: this.$t('document.posting.table.title.creditor'),
                        id   : 'creditor',
                    },

                ],
            };
        },
        
        /**
         * @inheritDoc 
         */
        computed: {
    
            /**
             * Status of document
             */
            status() {
                if (!this.document) {
                    return 'new';
                }
                if (this.document.finalized) {
                    return 'finalized';
                }
                if (this.document.balanced) {
                    return 'balanced';
                }
                return 'unbalanced';
            },
        },

        methods: {

            /**
             * Fetch currencies
             *
             * @returns {*}
             */
            fetch() {
                const params = { id: this.$route.params.id };
                return this.$services.Accountant.documentPrint(params).then((response) => {
                    this.document = response.data.results;
                    this.postings = response.data.results.postings;
                    this.sumAll(this.postings);
                    setTimeout(() => {
                        this.printSection();
                    }, 1000);
                }).catch((error) => {
                    this.handleError(error);
                    this.$router.push({
                        name: 'accountant-documents-list',
                    });
                }).finally(() => {
                    this.$wait.end('loading-page');
                });
            },

            /**
             * Sum All debtor and creditor
             *
             * @param items
             */
            sumAll(items) {
                Object.values(items).forEach((i) => {
                    this.allDebtor += i.debtor;
                    this.allCreditor += i.creditor;
                });
            },

            /**
             * Print Section
             */
            printSection() {
                const body = document.getElementById('invoice').innerHTML;
                const WinPrint = window.open('', '_blank', 'top=0,right=0,width=1600,height=794,toolbar=0,scrollbars=0,status=0,fullscreen=1');
                const { locale } = this.$i18n;
                const dir = this.$i18n.locale === 'fa' ? 'rtl' : 'ltr';
                WinPrint.document.write(`<!DOCTYPE html>
                <html dir="${dir}" lang="${locale}">
                  <head>
                    <style type="text/css">${this.$config('print.typo')}</style>
                    <style type="text/css">${this.$config('print.col')}</style>
                    <style type="text/css">${this.$config('print.table')}</style>
                    <style type="text/css">${this.$config('print.invoice')}</style>
                    <style type="text/css">${this.$config('print.document')}</style>
                    <title>${document.title}</title>
                  </head>
                  <body class="print print__document">
                    ${body}
                  </body>
                </html>`);
                setTimeout(() => {
                    WinPrint.focus();
                    WinPrint.print();
                    WinPrint.close();
                }, 1000);
            },

        },

    };

</script>
